// Custom container
@mixin make-container-custom() {
    @include make-container();
    max-width: 1300px;
}

// Small uppercase text
@mixin text-upp() {
    font-weight: 600;
    font-size: 10px;
    letter-spacing: 0.15em;
    text-transform: uppercase;
}
