.stat {
    background: $main-gradient;
    border-radius: $card-border-radius;
    box-shadow: $main-shadow;
    color: #fff;
}

.stat__row {
    display: flex;
    flex-wrap: wrap;
    background-position: center center;
    background-size: cover;
}

.stat__col {
    min-height: 160px;
    padding: 2.5rem $card-spacer-x;
    box-shadow: 1px 1px 0px 0px rgba(255,255,255,0.2);

    @include media-breakpoint-down(sm) {
        width: 50%;
    }

    @include media-breakpoint-up(md) {
        width: 25%;
    }
}

.stat__header {
    @include text-upp();
    margin-bottom: 7px;
}

.stat__value {
    font-size: 40px;
    transform: translateX(-2px);
}
