.c-table {
    @extend .table;
    @extend .table-hover;
    @extend .table-responsive;
    border: 0;

    td, th {
        @extend .align-middle;
    }

    th {
        @include text-upp();
    }

    td {
        &:not(:last-child) {
            border-right: 1px solid $table-border-color;
        }
    }

    .c-table__version {
        width: 7%;
        color: $primary;
        text-align: center;
    }

    .c-table__hostname {
        max-width: 43%;

        a {
            color: $body-color;
            font-size: .86rem;
            font-weight: 600;

            &:hover { color: $primary }
        }
    }

    .c-table__locale {
        width: 15%;
        color: $text-muted;
    }

    .c-table__useragent {
        width: 30%;
        color: $text-muted;
    }

    .c-table__enviroment {
        width: 5%;
        text-align: center;
    }

    .c-table__enviroment,
    .c-table__date {
        width: 5%;
        text-align: center;
        color: $text-muted;
    }
}
