.topbar {
    position: relative;
    height: 90px;
    background: #fff;

    .topbar__container {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        @include make-container-custom();
    }

    .topbar__logo {
        width: 60px;
    }

    .topbar__nav {
        position: absolute;
        right: calc($grid-gutter-width / 2);
    }

    &::after {
        content: '';
        position: absolute;
        top: 100%;
        z-index: -99;
        width: 100%;
        height: 70px;
        background: #fff;
        box-shadow: $main-shadow;
    }
}
