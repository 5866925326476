/* ----------------------------------
----------- Bootstrap
---------------------------------- */

$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #c1c9d0;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;

$blue:    #00B2FF;
$indigo:  #6610f2;
$purple:  #6f42c1;
$pink:    #FF005C;
$red:     #dc3545;
$orange:  #fd7e14;
$yellow:  #ffc107;
$green:   #1abb9c;
$teal:    #27D9B2;
$cyan:    #17a2b8;

$primary:       $green;
$secondary:     $gray-600;
$success:       $teal;
$info:          $blue;
$warning:       $yellow;
$danger:        $red;
$light:         $gray-100;
$dark:          $gray-800;

$body-bg: #f6f7f9;
$body-color: $gray-800;
$text-muted: $gray-400;

$font-family-sans-serif: 'Nunito', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-size-base: 0.8rem;

$card-border-width: 0;
$card-spacer-x: 2rem;

$btn-padding-x: 35px;
$btn-padding-y: .7rem;
$input-btn-padding-y: .6rem;
$input-border-color: $gray-300;
$input-btn-focus-width: 0;
$input-focus-border-color: $primary;

$table-cell-padding: 1.3rem;
$table-border-color: $gray-200;
$table-hover-bg: rgba(#000, .02);

$badge-font-size: 1rem;
$badge-padding-y: .36rem;
$badge-padding-x: .5rem;

$pagination-padding-y: .7rem;
$pagination-padding-x: 1rem;
$pagination-border-color: $body-bg;
$pagination-color: $body-color;
$pagination-disabled-color: $gray-400;
$pagination-disabled-border-color: $pagination-border-color;
$pagination-hover-color: $primary;
$pagination-hover-bg: #fff;
$pagination-hover-border-color: $body-bg;
$pagination-active-border-color: $body-bg;

/* ----------------------------------
----------- Custom
---------------------------------- */

$main-shadow: 0px 4px 27px 0px rgba(0,0,0,0.05);
$main-gradient: linear-gradient(234.69deg, #27D9B3 25.44%, $green 65.7%);
