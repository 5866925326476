// Custom container
.container-custom {
    @include make-container-custom();
}

// Add shadow to cards
.card {
    box-shadow: $main-shadow;
}

// Little uppercase text
.text-upp {
    @include text-upp();
}

// Main shadow
.box-shadow {
    box-shadow: $main-shadow;
}

// Buttons customizing
.btn {
    @include text-upp();
}

// Badge customizing
.badge {
    @include text-upp();
    color: #fff;
}

// Main gradient
.box-gradient {
    background: $main-gradient;
}

// Selected text
::selection {
    background: $primary;
    color: #fff;
}
::-moz-selection {
    background: $primary;
    color: #fff;
}

// Transparent input
.form-control.form-control--transparent {
    background-color: rgba(255, 255, 255, 0.2);
    border: 0;
    color: #fff;

    &::placeholder {
        color: #fff;
        opacity: .5;
    }
}

// Pagination
.pagination {
    & > li {
        @extend .page-item;

        & > a,
        & > span {
            @extend .page-link;
        }

        &.active {
            & > a,
            & > span {
                color: $primary;
            }
        }
    }
}
